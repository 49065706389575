.homeSlide h1 {
  font-size: 45px;
  line-height: 55px;
  margin: 50px 0 20px 0;
}
.homeSlide p {
  margin: 20px 0;
}
.homeSlide ul {
  position: absolute;
  bottom: 20px;
}
.homeSlide ul li {
  border: 2px solid #b8b7b7;
  border-radius: 50%;
  padding: 10px;
}
.homeSlide ul li button {
  background: transparent !important;
  color: transparent !important;
}
.homeSlide ul li button::before {
  font-size: 15px !important;
  color: #0f3460;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

@media (max-width: 768px) {
  .homeSlide h1 {
    font-size: 45px;
    line-height: 55px;
    margin: 50px 0 20px 0;
    color: #f2f2f2;
  }
  .homeSlide p {
    margin: 20px 0;
  }
  .homeSlide ul {
    position: absolute;
    bottom: 20px;
  }
  .homeSlide ul li {
    border: 2px solid #b8b7b7;
    border-radius: 50%;
    padding: 10px;
  }
  .homeSlide ul li button {
    background: transparent !important;
    color: transparent !important;
  }
  .homeSlide ul li button::before {
    font-size: 15px !important;
    color: #0f3460;
    position: absolute;
    top: 2px;
    left: 0;
    margin: auto;
  }




}
