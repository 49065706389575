footer {
  background-color: #0f3460;
  padding: 100px 0;
  color: #fff;
}
footer h1 {
  margin-bottom: 30px;
  color: #e94560;
  font-weight: 800;
  font-style: italic;
}

footer .img {
  background-color: #0c2a4d;
  padding: 15px 20px;
  border-radius: 5px;
}
footer .img i {
  font-size: 20px;
  margin: 5px;
}
footer .img span {
  margin-top: 5px;
}
footer p {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  margin-bottom: 20px;
}
footer h2 {
  margin-bottom: 20px;
}
footer li {
  margin-bottom: 10px;
  opacity: 0.5;
}

.grid3 {
  display: flex;
  justify-content: space-evenly;
}

.contact_address {
  width: 250px;
}

@media (max-width: 768px) {
  .footer {
    background-color: #0f3460;
    padding: 100px 0;
    color: #fff;
  }

  .grid3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  footer li {
    margin-bottom: 15px;
    opacity: 0.5;
    text-align: center;
  }

}
